import styled from "styled-components";
import { focusState } from "../tokens/a11y";

export const Button = styled.button`
  margin: initial;
  padding: initial;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
  color: inherit;

  display: block;
  width: 100%;
  max-width: 30ch;
  padding: 0.4em 0.8em;
  border: 1px solid ${({ theme }) => theme.palette.green};
  border-radius: 1rem;
  text-align: center;
  background-color: ${({ theme, secondary }) =>
    secondary ? "transparent" : theme.palette.green};
  color: ${({ theme, secondary }) =>
    secondary ? theme.palette.green : theme.palette.dark};
  transition: background-color 0.35s, color 0.35s;
  overflow: hidden;

  &:hover,
  &:focus {
    border-color: ${({ theme, secondary }) =>
      secondary ? theme.palette.green : theme.palette.olive};
    background-color: ${({ theme, secondary }) =>
      secondary ? "transparent" : theme.palette.olive};
    color: ${({ theme }) => theme.palette.softWhite};
  }

  &:focus {
    ${focusState};
  }
`;
