import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { hideVisually } from "polished";
import Layout from "../components/layout";
import { ReactComponent as FacebookIcon } from "../svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "../svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../svg/twitter.svg";
import ContactForm from "../components/contactForm";
import { focusState } from "../tokens/a11y";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: frontmatter.title }
  ];
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      pageTitle={frontmatter.title}
      preambleText={frontmatter.preambleText}
      metaDescription={frontmatter.metaDescription}
    >
      <PageContent>
        <ContactFormWrapper>
          <ContactForm />
        </ContactFormWrapper>
        <ContactBlocks>
          <EmailAndPhone>
            <EmailLink href="mailto:info@hydrorace.co.uk">
              info@hydrorace.co.uk
            </EmailLink>
            <Phone>+44 (0) 7739 456 583</Phone>
          </EmailAndPhone>
          <Social>
            <SocialLink href="https://www.instagram.com/hydrorace">
              <InstagramIcon css="fill: currentColor;" />{" "}
              <SocialLabel>Instagram</SocialLabel>
            </SocialLink>
            <SocialLink href="https://www.facebook.com/hydroracebottles">
              <FacebookIcon css="fill: currentColor;" />{" "}
              <SocialLabel>Facebook</SocialLabel>
            </SocialLink>
            <SocialLink href="https://www.twitter.com/hydrorace_">
              <TwitterIcon css="fill: currentColor;" />{" "}
              <SocialLabel>Twitter</SocialLabel>
            </SocialLink>
          </Social>
          <AddressBlock>
            <Address>
              15 Old Watford Road,
              <br />
              Bricket Wood,
              <br />
              Herts,
              <br />
              AL2 3RS
              <br />
              UK
            </Address>
            <VatNumber>VAT number: GB309 9502 89</VatNumber>
            <CompanyNumber>Company number: 10225202</CompanyNumber>
          </AddressBlock>
        </ContactBlocks>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </PageContent>
    </Layout>
  );
}

const PageContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin-top: -3rem;
  margin-right: -3vw;
  margin-left: -3vw;

  > * {
    margin-top: 3rem;
  }
`;

const ContactFormWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  min-width: 20em;

  padding-right: 3vw;
  padding-left: 3vw;
`;

const ContactBlocks = styled.div`
  display: grid;
  grid-gap: 2rem;

  flex-basis: 50%;
  flex-grow: 1;
  padding-right: 3vw;
  padding-left: 3vw;
`;

const EmailAndPhone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EmailLink = styled.a`
  display: inline-block;
  margin-right: -0.5em;
  margin-left: -0.5em;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;

  &:focus {
    ${focusState};
  }
`;

const Phone = styled.span`
  display: inline-block;
`;

const Social = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, 2rem);
  margin-right: -0.5em;
  margin-left: -0.5em;
`;

const SocialLink = styled.a`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;

  &:focus {
    ${focusState};
  }
`;

const SocialLabel = styled.span`
  ${hideVisually};
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Address = styled.address`
  font-style: normal;
`;

const CompanyNumber = styled.span`
  display: inline-block;
`;

const VatNumber = styled.span`
  display: inline-block;
  margin-top: 1em;
`;

const Content = styled.div`
  max-width: 60ch;

  padding-right: 3vw;
  padding-left: 3vw;
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        preambleText
        metaDescription
      }
    }
  }
`;
